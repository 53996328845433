<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="320"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-card class="b-dialog-question-card">
      <div class="b-dialog-question-card--header">
        <v-icon color="success" size="40"> fa-regular fa-circle-check </v-icon>
      </div>
      <div class="b-dialog-question-card--body">
        <h3>Deseja finalizar a solução?</h3>
        <p>
          Ao finalizar o chamado, o cliente irá receber um questionamento sobre
          sua solução, caso ele não concorde, o <strong>chamado</strong> voltará
          para o status de
          <strong>em andamento</strong>
        </p>
      </div>
      <div class="b-dialog-question-card--footer">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="success"
          title="Cancelar"
          @click="closeModal(false)"
        />
        <BaseButton
          id="btn-save"
          name="btn-save"
          color="success"
          title="Finalizar"
          @click="resolve"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    ticketId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    message: null
  }),

  methods: {
    closeModal(redirect) {
      this.$emit('closeModal', redirect);
    },

    async resolve() {
      try {
        const supportService = new SupportService();
        const { status } = await supportService.postResolveTicket(
          this.ticketId
        );

        if (status === 204) {
          showMessage('success', 'Chamado resolvido com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
